<template>
  <b-card class="p-2">
    <b-link @click="goBack">
      <feather-icon
        class="position-absolute text-primary"
        size="40"
        icon="ArrowLeftCircleIcon"
      />
    </b-link>
    <b-form ref="form">
      <b-row>
        <b-col
          class="mb-4 d-flex justify-content-center align-items-center"
          cols="12"
        >
          <feather-icon
            icon="MonitorIcon"
            size="25"
            class="mr-2"
          />
          <h1>Nuova Sala Riunioni</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-if="mediaUrl"
          class="mb-1"
          cols="12"
        >
          <b-img
            :src="mediaUrl"
            alt="Immagine Sala Riunioni"
            class="meetingroom_image"
          />
        </b-col>
        <b-col
          class="mb-5 text-center"
          cols="10"
        >
          <b-form-file
            v-model="mediaFile"
            :state="Boolean(mediaFile)"
            placeholder="Inserisci Immagine scegliendo un File o trascinandolo qui..."
            drop-placeholder="Trascina il File qui..."
            accept="image/*"
            @input="previewMedia"
          />
        </b-col>
        <b-col
          class="mb-5"
          cols="2"
        >
          <b-button
            variant="outline-primary"
            @click="removePreview"
          >
            Rimuovi
          </b-button>
        </b-col>
        <b-col
          class="mb-2"
          cols="12"
          md="6"
          lg="6"
        >
          <b-form-group
            label="Nome Sala Riunioni *"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="meetingroomData.name"
            />
          </b-form-group>
        </b-col>
        <b-col
          class="mb-2"
          cols="12"
          md="6"
          lg="6"
        >
          <b-form-group
            label="N° Posti Massimo *"
            label-for="max_persons"
          >
            <b-form-input
              id="max_persons"
              v-model="meetingroomData.max_persons"
            />
          </b-form-group>
        </b-col>
        <b-col
          class="mb-2"
          cols="12"
          md="6"
          lg="6"
        >
          <b-form-group
            label="Descrizione"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="meetingroomData.description"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="headquarters"
          class="mb-2"
          cols="12"
          md="6"
          lg="6"
        >
          <b-form-group
            label="Sede di Appartenenza *"
            label-for="headquarter_id"
          >
            <b-form-select
              id="headquarter_id"
              v-model="meetingroomData.headquarter_id"
              :options="headquarters"
            />
          </b-form-group>
        </b-col>
        <b-col
          class="mb-2"
          cols="12"
        >
          <b-form-group
            label="Note"
            label-for="note"
          >
            <b-form-textarea
              id="note"
              v-model="meetingroomData.note"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          class="mb-2"
          cols="12"
        >
          <h2>Caratteristiche</h2>
          <label for="features">Premi Invio per inserire la Caratteristica</label>
          <b-form-tags
            v-model="meetingroomData.features"
            input-id="features"
            class="mb-2"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          class="text-right mt-2"
          cols="12"
        >
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="addMeetingroom"
          >
            <b-spinner
              v-if="loading"
              variant="light"
              small
            />
            <span v-if="!loading">Aggiungi</span>
          </b-button>
          <b-button
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="reset"
          >
            Pulisci
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormTextarea,
  BFormFile,
  BFormTags,
  BLink,
  BFormSelect,
  BImg,
  BSpinner,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import headquartersStoreModule from '@/views/apps/headquarters/headquartersStoreModule'
import store from '@/store'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import meetingroomsStoreModule from '../meetingroomsStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BButton,
    BFormTags,
    BLink,
    BFormSelect,
    BImg,
    BSpinner,
  },
  setup() {
    const toast = useToast()
    const MEETINGROOMS_APP_STORE_MODULE_NAME = 'app-meetingrooms'
    const HEADQUARTERS_APP_STORE_MODULE_NAME = 'app-headquarters'

    // Register module
    if (!store.hasModule(MEETINGROOMS_APP_STORE_MODULE_NAME)) {
      store.registerModule(MEETINGROOMS_APP_STORE_MODULE_NAME, meetingroomsStoreModule)
    }
    if (!store.hasModule(HEADQUARTERS_APP_STORE_MODULE_NAME)) {
      store.registerModule(HEADQUARTERS_APP_STORE_MODULE_NAME, headquartersStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MEETINGROOMS_APP_STORE_MODULE_NAME)) { store.unregisterModule(MEETINGROOMS_APP_STORE_MODULE_NAME) }
      if (store.hasModule(HEADQUARTERS_APP_STORE_MODULE_NAME)) { store.unregisterModule(HEADQUARTERS_APP_STORE_MODULE_NAME) }
    })

    const headquarters = ref(null)
    const _headquarters = [
      {
        value: null,
        text: 'Seleziona una Sede',
      },
    ]
    store
      .dispatch('app-headquarters/fetchHeadquarters')
      .then(response => {
        response.data[0].forEach((headquarter, i) => {
          const _headquarter = {
            value: headquarter.id,
            text: headquarter.name,
          }
          _headquarters.push(_headquarter)
        })
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })
    headquarters.value = _headquarters

    return {
      meetingroomData: {
        name: null,
        max_persons: null,
        description: null,
        note: null,
        headquarter_id: null,
        features: [],
      },
      headquarters,
      loading: false,
      mediaFile: null,
      mediaUrl: null,
    }
  },
  methods: {
    previewMedia() {
      if (this.mediaFile) this.mediaUrl = URL.createObjectURL(this.mediaFile)
    },
    removePreview() {
      URL.revokeObjectURL(this.mediaUrl)
      this.mediaUrl = null
      this.mediaFile = null
    },
    goBack() {
      this.$router.go(-1)
    },
    reset() {
      this.$refs.form.reset()
    },
    addMeetingroom() {
      const valid = this.validation()
      if (!valid) return
      this.loading = true
      const formData = new FormData()
      formData.append('name', this.meetingroomData.name)
      formData.append('max_persons', this.meetingroomData.max_persons)
      formData.append('description', this.meetingroomData.description)
      formData.append('note', this.meetingroomData.note)
      formData.append('headquarter_id', this.meetingroomData.headquarter_id)
      if (this.meetingroomData.features && this.meetingroomData.features.length > 0) { formData.append('features', this.meetingroomData.features) }
      if (this.mediaFile) formData.append('image', this.mediaFile)
      store
        .dispatch('app-meetingrooms/addMeetingroom', formData)
        .then(result => {
          this.$router.replace({ name: 'apps-meetingrooms-list' }).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Sala Riunioni #${result.data.id} aggiunta con successo`,
                icon: 'MonitorIcon',
                variant: 'success',
              },
            })
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
      this.loading = false
    },
    validation() {
      if (!this.meetingroomData.name) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Nome',
            icon: 'AlertTriangleIcon',
            text: 'Inserire il Nome della Sala Riunioni per proseguire.',
            variant: 'danger',
          },
        })
        return false
      }
      if (!this.meetingroomData.max_persons) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Posti',
            icon: 'AlertTriangleIcon',
            text: 'Inserire il Numero di Posti Massimo per proseguire.',
            variant: 'danger',
          },
        })
        return false
      }
      if (!this.meetingroomData.headquarter_id) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Sede',
            icon: 'AlertTriangleIcon',
            text: 'Scegliere la Sede di Appartenenza per proseguire.',
            variant: 'danger',
          },
        })
        return false
      }
      return true
    },
  },
}
</script>

<style>
.position-absolute {
  z-index: 1;
}
.meetingroom_image {
  width: 100%;
  max-height: 500px;
  object-position: center;
  object-fit: cover;
  border-radius: 5px;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Sfoglia';
}
</style>
